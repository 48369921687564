<template>
  <main class="page">
    <div class="container" style="min-height: 80vh;">
      <h1 class="text-primary"> Error</h1>
      
     <div class="col-md-12">
        <div class="card ">
          <div class="card-header text-white bg-danger">
            <h5>Error</h5>
          </div>
            
          <div class="card-body">
              <p> An error has occurred. this may be to due to invalid input. contact <strong> System Administrator  </strong> for more details </p>
            </div>
        </div>
     </div>
     </div>
       
    </main>
</template>

<script>


export default {

  
}
</script>

<style scoped>
  .page {
    padding-top: 4em;
    padding-bottom: 4em;
  }
</style>